import React, { Component } from 'react'

import { Dialog } from 'primereact/dialog';


export default class SignatureView extends Component {
    constructor(props) {
        super(props);

    }





    render() {
        const { signature } = this.props;
        return (
            <Dialog header={'Signature Details'}
                blockScroll={true} draggable={false}
                closeOnEscape={true}
                dismissableMask={true} visible={true}
                closable={true}
                style={{ width: '50%' }}
                onHide={this.props.onHide}>
                <div className='appointment-info'>
                    {/* <div>
                        <span className='label-l1'> Signature Name: </span>
                        <span className='label-v1'> {signature.signatureName}</span>
                    </div> */}
                    <div>
                        {/* <span className='label-l1'> Signature: </span> */}
                        <div className='signature-w' dangerouslySetInnerHTML={{ __html: signature.signature }}></div>
                    </div>
                </div>
            </Dialog >
        )
    }
}
