import React, { Component } from 'react'

import { Dialog } from 'primereact/dialog';


export default class UserView extends Component {
    constructor(props) {
        super(props);
    }





    render() {
        const { user } = this.props;
        return (
            <Dialog header={'User Details'}
                blockScroll={true} draggable={false}
                closeOnEscape={true}
                dismissableMask={true} visible={true}
                closable={true}
                style={{ width: '50%' }}
                onHide={this.props.onHide}>
                <div className='appointment-info'>
                    <div>
                        <span className='label-l1'> Name: </span>
                        <span className='label-v1'> {user.name}</span>
                    </div>
                    <div>
                        <span className='label-l1'> Subject: </span>
                        <span className='label-v1'> {user.subject}</span>
                    </div>
                    <div>
                        <span className='label-l1'> Message: </span>
                        <div className='user-w' dangerouslySetInnerHTML={{ __html: user.message }}></div>
                    </div>
                </div>
            </Dialog >
        )
    }
}
