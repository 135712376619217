import {
    combineReducers
} from 'redux';
import user from "./userDataReducer";
import permissions from './permissionsReducer';

const rootReducer = combineReducers({
    user,
    permissions


});


export default rootReducer;